// Importing necessary components from Material-UI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
// Importing React and its type functionalities
import type { FC } from 'react';
import React from 'react';

// Interface for the component's props
interface ConfirmModalProps {
  open?: boolean;
  handleClose?: () => void;
  continueEdit?: () => void;
  message?: string;
  type?: 'yesno' | 'cancelsubmit' | 'uploadFilesWarning' | 'ok' | 'gobackConfirm';
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  buttonSize?: 'small' | 'medium' | 'large';
  children : any
}

// ConfirmModal component definition
const ConfirmModal: FC<ConfirmModalProps> = ({
  open = false,
  handleClose = () => null,
  continueEdit = () => null,
  message = '',
  type = 'yesno',
  maxWidth = 'xs',
  buttonSize = 'small',
  children
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirmation-dialog"
      maxWidth={maxWidth}
    >
      {/* Dialog title displaying the message */}
      <DialogTitle id="confirmation-dialog-title text-center" className='text-center font-medium'>
        {message}
      </DialogTitle>
       <DialogContent>
        {children}


      {/* Dialog actions based on the type of confirmation */}
      {type && type === 'yesno' && (
        // Actions for 'yesno' type
        <DialogActions className='flex justify-between'>
          {/* Button for 'YES' action */}
          <Button
            onClick={continueEdit}
            color="primary"
            variant="outlined"
            size={buttonSize}
            sx={{
              padding:"0px",
              height: "50px",
            }}
            className='text-sm font-semibold'
            fullWidth
          >
            Yes
          </Button>
          {/* Button for 'NO' action */}
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            size={buttonSize}
            sx={{
              padding:"0px",
              height: "50px",
            }}
            className='text-sm font-semibold'
            fullWidth
          >
            No
          </Button>
        </DialogActions>
      )}
      {type && type === 'cancelsubmit' && (
        // Actions for 'cancelsubmit' type
        <DialogActions>
          {/* Button for 'Cancel' action */}
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            size={buttonSize}
          >
            Cancel
          </Button>
        </DialogActions>
      )}
      {type && type === 'uploadFilesWarning' && (
        // Actions for 'uploadFilesWarning' type
        <DialogActions>
          {/* Button for 'Retry' action */}
          <Button
            onClick={continueEdit}
            color="primary"
            variant="outlined"
            size={buttonSize}
          >
            Retry
          </Button>
          {/* Button for 'Close' action */}
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            size={buttonSize}
          >
            Close
          </Button>
        </DialogActions>
      )}
      {type && type === 'ok' && (
        // Actions for 'ok' type
        <DialogActions>
          {/* Button for 'OK' action */}
          <Button
            onClick={continueEdit}
            color="primary"
            variant="contained"
            size={buttonSize}
          >
            OK
          </Button>
        </DialogActions>
      )}
      {type && type === 'gobackConfirm' && (
        // Actions for 'gobackConfirm' type
        <DialogActions className='flex justify-between'>
       {/* Button for 'Go Back' action */}
        <Button
          onClick={handleClose}
          color="primary"
          variant="outlined"
          size={buttonSize}
          sx={{
            padding:"0px",
            height: "50px",
          }}
          className='text-sm font-semibold'
          fullWidth
        >
          Go Back
        </Button>
        
         {/* Button for 'Confirm' action */}
        <Button
          onClick={continueEdit}
          // color="primary"
          variant="contained"
          type='submit'
          size={buttonSize}
          sx={{
            padding:"0px",
            height: "50px",
          }}
          className='text-sm font-semibold'
          fullWidth
        >
          Confirm
        </Button>
      </DialogActions>
      )}
           </DialogContent>
    </Dialog>
  );
};

// Exporting the component
export default ConfirmModal;
