import React, { useEffect, useMemo, useState } from "react";
import ShowBreadCrumb from "./BreadCrumb";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Button,
  Card,
  CardContent,
  styled,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BackAndSaveButton from "pages/Claims/Collision/BackAndSaveButton";
import PaymentMethodIcon from "assets/Images/PaymentMethodIcon.svg";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { Breadcrumbs } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FetchBillsAPI,
  FetchNextBillAPI,
  useFundingAccountsQuery,
  useMakePaymentWithFFundingToken,
} from "queries/BillsQueries";
import HandleErrorResponse from "helper/HandleErrorResponse";
import ConfirmModal from "components/Common/ConfirmModal";

const breadcrumbItems = [
  { label: "Bills", href: "/bills" },
  { label: "Make Payment", href: "/select-payment-method" },
];

const allCardData = [
  { id: 1, cardName: "Debit Card/Credit Card" },
  { id: 2, cardName: "ACH" },
];

const SelectMethod = () => {
  const { mutate: makePaymentWithFundingToken } =
    useMakePaymentWithFFundingToken();

  const navigate = useNavigate();
  const location = useLocation();
  const [selectedCard, setSelectedCard] = useState<null | number>(null);
  const { userEnteredAmount } = location.state || {};
  const { data: receivedBills } = FetchBillsAPI(1, 50, "ASC", false);
  const { data: bill } = FetchNextBillAPI();
  const { amountDue, policyNo, invoiceId } = bill?.data || {};
  const {
    getBillFromLocation,
    invoiceId: billInvoiceId,
    policyNo: billPolicyNo,
    amountDue: billAmountDue,
  } = location.state?.billData || {};
  const [paymentConfirmation, setPaymentConfirmation] = useState(null)

  const getPolicyNumber = () => {
    if (getBillFromLocation) {
      return billPolicyNo;
    }
    return policyNo || receivedBills?.data?.[0]?.policyNo || "policy No";
  };

  const { data: fundingAccountsCardData } = useFundingAccountsQuery({
    page: 1,
    pageSize: 50,
    sort: "DESC",
    billerAccountId: getPolicyNumber(),
  });
  const fundingAccountCards = fundingAccountsCardData?.data;

  const handleChange = (e: any) => {
    setSelectedCard(e.target.value);
    console.log("clicked", e.target.value);
  };


  const getAmountPaid = () => {
    if(getBillFromLocation){
      return billAmountDue;
    }
    return amountDue || Number(userEnteredAmount) || 'amount'
  }

  const handleProceedPayment = () => {
    makePaymentWithFundingToken(
      {
        amountPaid: getAmountPaid(),
        fundingAccountId: selectedCard,
        policyNumber: getPolicyNumber(),
      },
      {
        onSuccess: (data) => {
          const status = data?.data?.status;

          if (status === "success") {
            navigate("/payment-success", {
              state: { responseData: data?.data },
            });
          } else {
            navigate("/payment-denied", {
              state: { responseData: data?.data },
            });
          }
        },
        onError: (err) => {
          HandleErrorResponse(err);
        },
      }
    );
  }
  const handleClick = () => {
    const selectedMethodInfo =fundingAccountCards.find((item : any) => item.fundingToken === selectedCard);
    if(selectedMethodInfo.paymentMethodKind === "CreditCard"){
      setPaymentConfirmation(selectedMethodInfo)
    }
    else {
      handleProceedPayment()
    }
  };

  const navigateToPrevScreen = () => {
    navigate("/bills");
  };

  const handlePaymentModeClick = (mode: any) => {
    mode == 1
      ? navigate("/add-card", {
          state: { userEnteredAmount },
        })
      : navigate("/direct-debit", {
          state: { userEnteredAmount },
        });
  };

  useEffect(() => {
    setSelectedCard(fundingAccountCards?.[0]?.fundingToken);
  }, [fundingAccountCards]);

  return (
    <div>
      <Grid item xs={12}>
        <Breadcrumbs className="mt-4" aria-label="breadcrumb">
          {breadcrumbItems.map((item, index) => (
            <Link
              key={index}
              to={item.href}
              className={index === breadcrumbItems.length - 1 ? "lastLink" : ""}
              style={
                index === breadcrumbItems.length - 1
                  ? { outline: "none", color: "#BB3330", fontWeight: "600" }
                  : {
                      outline: "none",
                      textDecoration: "none",
                      color: "#545454",
                    }
              }
            >
              {item.label}
            </Link>
          ))}
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Typography className="font-semibold text-lg leading-7 mt-5">
          Select Payment Method
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={selectedCard}
            onChange={handleChange}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 17,
              },
            }}
          >
            {fundingAccountCards?.map((card: any) => {
              const {
                fundingToken,
                accountName,
                truncatedCardNo,
                isPrimary,
                truncatedDdaNo,
                paymentMethodKind,
              } = card;
              return paymentMethodKind === "Ach" ? (
                <PaymentCardRadioButton
                  id={fundingToken}
                  isPrimary={isPrimary}
                  bankName={accountName}
                  cardNumber={truncatedDdaNo}
                />
              ) : (
                <PaymentCardRadioButton
                  id={fundingToken}
                  isPrimary={isPrimary}
                  bankName={accountName}
                  cardNumber={truncatedCardNo}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ border: "1px solid #EDEDED" }} className="mt-4">
          {allCardData?.map((info, index) => {
            const { id, cardName } = info;
            return (
              <div
                tabIndex={0}
                onClick={() => handlePaymentModeClick(id)}
                className="cursor-pointer"
              >
                <CardContent
                  sx={{ borderBottom: index === 0 ? "1px solid #EDEDED" : "" }}
                >
                  <Box className="flex justify-between">
                    <Box className="flex items-center gap-4">
                      {id === 2 ? <AccountBalanceIcon /> : <CreditCardIcon />}
                      {cardName}
                    </Box>
                    <ChevronRightIcon />
                  </Box>
                </CardContent>
              </div>
            );
          })}
        </Card>
      </Grid>
      <BackAndSaveButton
        isValid={!!selectedCard}
        backButtonHandler={navigateToPrevScreen}
        saveAndNextHandler={handleClick}
        saveAndNextText="Proceed to payment"
      />
            <ConfirmModal
        handleClose={() => setPaymentConfirmation(null)}
        open={paymentConfirmation !== null}
        message={
          "Confirm Payment"
        }
        buttonSize="small"
        continueEdit={handleProceedPayment}
        type="gobackConfirm"
      >
        <Box className="mb-4">
          {(
            <span className="text-sm">
              {" "}
              There will be a 2.55% of ${getAmountPaid()} credit card processing fee.
              <br/>
              Would you like to proceed?
            </span>
          )}
        </Box>
      </ConfirmModal>
    </div>
  );
};

export default SelectMethod;

const PaymentCardRadioButton = ({
  id,
  isPrimary,
  bankName,
  cardNumber,
}: any) => {
  return (
    <div key={id} className="border-solid border-gray-100 rounded-lg p-4 mt-5">
      <div className="flex justify-between items-center">
        <FormControlLabel
          value={id}
          control={<Radio />}
          label={
            <Typography fontSize={14} fontWeight={600}>
              <Box className="flex justify-between gap-6">
                <Box>
                  <Typography variant="subtitle1" className="text-sm font-bold">
                    {bankName}
                  </Typography>
                  <Typography variant="subtitle1" className="text-sm font-bold">
                    **** **** **** {cardNumber}
                  </Typography>
                </Box>
                {isPrimary && (
                  <Box className="flex items-baseline">
                    <Typography
                      variant="subtitle1"
                      className="text-sm font-semibold text-[#095F3C]"
                    >
                      Primary
                    </Typography>
                  </Box>
                )}
              </Box>
            </Typography>
          }
        />
      </div>
    </div>
  );
};
